<template>
  <BListCard
    :searchActive="true"
    :newAddActive="true"
    :totalActive="true"
    :show="show"
    :title="'Üye Grup İşlemleri'"
    :total="rows.length"
    :type="type"
    @newAdd="handlerNewItem"
    @searchInput="search = $event"
    @handlerClose="type = $event"
  >
    <template v-slot:leftBody>
      <b-alert variant="warning" show class="text-center m-3" v-if="!rows.length">
        <h4 class="alert-heading">Bilgi!</h4>
        <p>Henüz Kayıt Bulunmamaktadır.</p>
      </b-alert>

      <ul class="list-group">
        <UyeGrupItem v-for="(item, index) in rows" :key="index" :item="item" @handlerUpdate="handlerUpdate($event)" />
      </ul>
    </template>
    <template v-slot:rightBody>
      <div class="p-3">
        <b-alert variant="info" show class="text-center" v-if="!type">
          <h4 class="alert-heading">Bilgi!</h4>
          <p>Lütfen Yeni Kayıt Ekleme/Düzenlemek İçin İşlem Seçimi Yapınız.</p>
        </b-alert>
        <UyeGrupEkle
          v-else
          :updateData="updateData"
          @show="show = $event"
          @updateRefresh="handlerUpdatedData"
          @handlerClose="type = $event"
        />
      </div>
    </template>
  </BListCard>
</template>

<script>
import BListCard from '@/components/cards/BListCard.vue';
import { ref, watch, defineComponent, computed } from '@vue/composition-api';
import store from '@/store';
import UyeGrupEkle from './component/UyeGrupEkle.vue';
import UyeGrupItem from './component/UyeGrupItem.vue';
import { useToast } from 'vue-toastification/composition';
import draggable from 'vuedraggable';
export default defineComponent({
  components: {
    draggable,
    BListCard,
    UyeGrupEkle,
    UyeGrupItem,
  },
  setup() {
    const expo = {};
    const toast = useToast();
    expo.search = ref(null);
    expo.type = ref(false);
    expo.show = ref(false);
    expo.updateData = ref({});
    expo.rows = ref([]);
    expo.form = ref({
      k_no: null,
      baslik: 'Yeni Uye Grubu',
      oran: 0,
      statu: true,
    });

    const handlerGetData = async () => {
      expo.show.value = true;
      await store.dispatch('uyeGrupListele');
      expo.rows.value = store.getters.getUyeGrupListele;
      expo.show.value = false;
    };

    handlerGetData();

    expo.handlerNewItem = () => {
      expo.show.value = true;
      store
        .dispatch('uyeGrupEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Ekleme Başarılı.', { position: 'bottom-left' });
            expo.updateData.value = res.data.data;
            expo.type.value = true;
            expo.show.value = false;
          }
        })
        .catch((err) => {
          if (err.success === false) {
            expo.show.value = false;
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    expo.handlerUpdate = async (event) => {
      expo.show.value = true;
      expo.updateData.value = await event;
      expo.type.value = true;
      expo.show.value = false;
    };

    expo.handlerUpdatedData = () => {
      expo.rows.value = store.getters.getUyeGrupListele;
    };

    watch(expo.search, (val) => {
      if (val.length > 0) {
        const search = val.toLocaleLowerCase('TR').trim();
        let filteredData = store.getters.getUyeGrupListele;
        filteredData = filteredData.filter((rows) => {
          return rows.icerik['tr'].baslik.toLocaleLowerCase('TR').includes(search);
        });
        expo.rows.value = filteredData;
      } else {
        expo.rows.value = store.getters.getUyeGrupListele;
      }
    });

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
