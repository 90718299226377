<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
      <b-row>
        <b-col cols="12 pt-3">
          <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Başlık">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="baslik"
                v-model="form.baslik"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12 pt-3">
          <validation-provider name="İndirim Oranı" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="İndirim Oranı (%)">
              <b-input-group>
                <b-form-input
                  size="lg"
                  type="number"
                  class="rounded-0"
                  ref="baslik"
                  v-model="form.oran"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
                <b-input-group-append is-text> % </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-center py-3">
            <label for="statu" class="h6">Kullanım Durumu</label>
            <b-form-checkbox :checked="form.statu" v-model="form.statu" switch size="lg" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed, toRefs, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.firmaKNo = computed(() => store.getters.getUserData.firma_k_no);
    const { updateData } = toRefs(props);

    expo.form = ref({
      k_no: null,
      baslik: null,
      oran: 0,
      statu: true,
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('uyeGrupGuncelle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
            context.emit('updateRefresh', res.data.data);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length) {
          expo.form.value = { ...val };
        } else {
          expo.resetForm();
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
